<template>
  <div>
    <v-card elevation="2" tile >
      <v-card-text class="subnote">Información No Oficial, en caso de requerir un reporte oficialmente realizarlo a través de la plataforma de Titan.</v-card-text>
    </v-card>
    <v-card elevation="2" tile>
      <v-card-title style="float: left">Categorías de Consultas.</v-card-title>
      <!-- <CButton
        color="primary"
        class="px-4"
        style="float: right"
        @click="crear()"
        >+ Nuevo</CButton
      > -->

      <v-card-text>
        
        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status"></div>
        </div>
        <CDataTable
          v-if="!loading"
          hover
          striped
          :items="idsCategoriasPermitidas"
          :fields="fields"
          :items-per-page="50"
          :active-page="activePage"
          :pagination="{ doubleArrows: false, align: 'center' }"
          @page-change="pageChange"
        >
          <template #activo="data">
            <td>
              <CBadge :color="data.item.activo?'success':'secondary'">
                {{ data.item.activo==1?'Habilitado':'Inhabilitado' }}
              </CBadge>
            </td>
          </template>
          <template #acciones="data">
            <td>
              <CLink
                href="#"
                @click="verConsultas(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Ver las consultas de esta categoría'"
              >
                <CIcon name="cil-list" />
              </CLink>
            </td>
          </template>
        </CDataTable>
      </v-card-text>
    </v-card>
    <br><br>
  </div>
</template>

<script>
import Filters from "@/components/filters";
import definicionConsultaService from "@/services/definicionConsulta.service";
import ListaService from "@/services/lista.service";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";

export default {
  name: "Layout",
  data() {
    return {
      items: [],
      fields: [
        { key: "id", label: "Código" },
        { key: "nombre", label: "Categoría", _classes: "font-weight-bold" },
        { key: "acciones", label: "Consultas de la categoría" },
      ],
      filterFields: [
        { value: "id", label: "Código" },
        { value: "nombre", label: "Nombre" },
        {
          value: "categoria_id",
          label: "Categoría",
          options: [],
        },
      ],
      rolesStr: [],
      consultas: [],
      idsCategoriasPermitidas: [],
      activePage: 1,
      loading: false,
      message: "",
    };
  },
  components: {
    // Filters,
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  computed: {
    ...mapState(["permissions", "roles"]),
  },
  mounted() {
    this.rolesStr = this.roles.map(item => item.name)
    if (!this.permissions.includes("Autogestor - Consulta"))
      this.$router.push("/dashboard");
    // this.fetchItems("?activo=1");
    this.fetchConsultas("?activo=1");
    EventBus.on("fetchItemsEvent", (cadenaBusqueda) => {
      // this.fetchItems(cadenaBusqueda);
    });
  },
  methods: {
    fetchConsultas(cadenaBusqueda) {
      this.loading = true;
      definicionConsultaService.getItems(cadenaBusqueda).then(
        (response) => {
          this.consultas = response.data;
          
          this.consultas = this.consultas.filter( this.checkRoles )
          // console.log("consultas permitidas:")
          // console.log(this.consultas)
            
          this.idsCategoriasPermitidas = this.consultas.map(function(item){
            return {id: item.categoria_id, nombre: item.categoria};
          });

          // Se retiran los ids duplicados
          const result = [];
          let set                 = new Set( this.idsCategoriasPermitidas.map( JSON.stringify ) )
          this.idsCategoriasPermitidas = Array.from( set ).map( JSON.parse );
          
          // console.log("Retirando duplicados")
          // console.table(this.idsCategoriasPermitidas)

          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    checkRoles(item) {
      let rolesPermitidos = JSON.parse(item.roles_acceso)
      let acceso = false
      for(let i = 0; i <rolesPermitidos.length; i++) {
        if (this.rolesStr.includes(rolesPermitidos[i]))
          acceso = true
      }
      return acceso
    },
    // fetchItems(cadenaBusqueda) {
    //   this.loading = true;
    //   ListaService.getCategoriasConsultas().then(
    //     (response) => {
    //       this.items = response.data.data;
    //       console.log(this.items)
          
    //       this.loading = false;
    //     },
    //     (error) => {
    //       this.content =
    //         (error.response &&
    //           error.response.data &&
    //           error.response.data.message) ||
    //         error.message ||
    //         error.toString();
    //       this.loading = false;
    //     }
    //   );
    // },
    verConsultas(categoria) {
      localStorage.setItem("datos_autogestor_categoria", JSON.stringify(categoria));
      this.$router.push({ path: `categorias/consultas/` });
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
};
</script>
