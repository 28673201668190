import api from './api';

class DefinicionConsultaService {

  getItems(cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/definiciones_consulta${cadenaBusqueda}`);
  }

  newItem(params) {
    return api.post(`/api/definiciones_consulta/`, params)
  }

  editItem(params) {
    return api.put(`/api/definiciones_consulta/${params.id}`, params)
  }

  deleteItem(procesoId) {
    return api.delete(`/api/definiciones_consulta/${procesoId}`)
  }

  consultar(params) {
    return api.post(`/api/autogestor/consultar/`, params)
  }

  consultarTest(params) {
    return api.post(`/api/autogestor/consultar_test/`, params)
  }

  consultarResultado(params) {
    return api.post(`/api/autogestor/consultar/resultado/`, params)
  }

  getArchivo(idTemp) {
    return api.get(`/api/autogestor/descargar/${idTemp}`, { responseType: 'blob' });
  }

  
}

export default new DefinicionConsultaService();
